import Pagination from "components/shared/Pagination";
import React, { useState } from "react";
import Locale from "translations";
import CheckedTraveler from "./CheckedTraveler.js";

function ListCheckedTravelers({
	allTravelers,
	setAllTravelers,
	meta,
	filter,
	setfilter,
}) {
	const { groups, Labs, teamManagement } = Locale;
	const [modal, setModal] = useState(false);
	const [examinationResult, setExaminationResult] = useState({});

	const toggle = () => {
		setModal(!modal);
	};

	const statues = [
		{ value: "positive", label: Labs.positive },
		{ value: "negative", label: Labs.negative },
	];

	const goTo = async (page) => {
		setfilter({ ...filter, page: page });
	};

	const Alltravelers =
		allTravelers &&
		allTravelers.length > 0 &&
		allTravelers.map((traveler, index) => {
			return <CheckedTraveler traveler={traveler} key={index} />;
		});

	return (
		<>
			<div className="medical-result">
				<div className="TeamManagement-header">
					<div className="TeamManagement-title">{Labs.travelers}</div>
				</div>

				<div className="reservation-table">
					<table className="table table-striped mb-0">
						<thead>
							<tr className="bg-grey text-white">
								<th scope="col">{teamManagement.region}</th>
								<th scope="col">{teamManagement.hotel}</th>
								<th scope="col">{teamManagement.dateInOut}</th>
								<th scope="col">{Labs.supervisor}</th>
								<th scope="col">{Labs.passportNumber}</th>
								<th scope="col">{Labs.pilgrimName}</th>
								<th scope="col">{Labs.nationalId}</th>
								<th scope="col">{Labs.gender}</th>
								<th scope="col">{Labs.date_of_birth}</th>
								<th scope="col">{Labs.analysisResults}</th>
								<th scope="col">{Labs.processes}</th>
							</tr>
						</thead>
						<tbody>
							{Alltravelers && Alltravelers.length > 0 ? (
								Alltravelers
							) : (
								<tr>
									<td colSpan="11">
										<div className="product-build__product-no-data">
											<i className="fas fa-info-circle fa-lg"></i>{" "}
											<h4>{Labs.noResult}</h4>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
				<Pagination info={meta} goTo={goTo} items={10} />
				{/* Modal for view PCR result */}
			</div>
		</>
	);
}

export default ListCheckedTravelers;
