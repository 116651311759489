import React from "react";
import Locale from "translations";

export default function Activity({ logsActivity = [] }) {
	const { statistics } = Locale;

	const returnLogs = (logsItem) => {
		return logsItem.map((data) => (
			<div className="main pt-3 d-flex" key={data.id}>
				<div className="time font-weight-bold">
					{new Date(data.login_at).getHours() +
						":" +
						(new Date(data.login_at).getMinutes() < 10 ? "0" : "") +
						new Date(data.login_at).getMinutes()}
				</div>
				<div className="px-2">
					<span className="tes">
						<i
							className={`far fa-circle bg-white ${
								data.login_successful ? "text-primary" : "text-danger"
							}`}
						></i>
					</span>
				</div>
				<div>
					<h2
						className={`text-uppercase font-weight-bold ${
							data.login_successful ? "text-primary" : "text-danger"
						}`}
					>
						{statistics.signIn}
					</h2>
					<p>{data.location.city + " - " + data.location.country}</p>
					<p>{data.ip_address + " | " + data.user_agent}</p>
				</div>
			</div>
		));
	};

	let ActivitiesReturned = Object.entries(logsActivity).map((item) => {
		return (
			console.log(item),
			(
				<React.Fragment>
					<div className="date">
						<p>{item[0]}</p>
					</div>
					{returnLogs(item[1])}
				</React.Fragment>
			)
		);
	});

	return (
		<>
			<div className="p-2 activity bg-white shadow-sm rounded">
				<div className="title">
					<h2 className="font-weight-bold text-subtitle">
						{statistics.myActivity}
					</h2>
					<p className="text">{statistics.activityText}</p>
				</div>
				{ActivitiesReturned}
			</div>
		</>
	);
}
