import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import React from "react";
import { attachment } from "services/lab";
import Locale from "translations";
function Traveler({
	traveler,
	toggle,
	startAnalysis,
	examinationResult,
	setExaminationResult,
}) {
	const locale = localStorage.getItem("currentLocale") || "en";
	const { groups, Labs, teamManagement } = Locale;
	return (
		<>
			<tr>
				<td scope="col">{traveler.traveller.traveller_name}</td>
				<td scope="col">{traveler.traveller.passport_num}</td>
				<td scope="col">{traveler.traveller.nin}</td>
				<td scope="col">{traveler.traveller.gender}</td>
				<td scope="col">{traveler.traveller.date_of_birth}</td>
				<td scope="col">
					<p>
						{moment(traveler.examination_date, "DD-MM-YYYY HH:mm aa").format(
							"DD-MM-yyyy"
						)}
					</p>
					<p>
						{moment(traveler.examination_date, "DD-MM-YYYY HH:mm aa").format(
							"HH:mm"
						)}
					</p>
				</td>
				<td scope="col">
					{traveler.examination.analysis_date ? (
						<div>
							<p className="text-success font-weight-bold">
								{Labs.ExaminationDone}
							</p>
							{moment(
								traveler.examination.analysis_date,
								"DD-MM-YYYY HH:mm aa"
							).format("DD-MM-yyyy")}
						</div>
					) : (
						<i className="fa fa-window-minimize text-secondary"></i>
					)}

					<p>
						{traveler.examination.analysis_date
							? moment(
									traveler.examination.analysis_date,
									"DD-MM-YYYY HH:mm aa"
							  ).format("HH:mm")
							: " "}
					</p>
				</td>
				<td scope="col">
					<p
						className={`${
							traveler.examination.status === "negative"
								? "text-success font-weight-bold"
								: traveler.examination.status === "positive"
								? "text-danger font-weight-bold"
								: "text-secondary "
						}`}
					>
						{traveler.examination.status === "negative"
							? Labs.negative
							: traveler.examination.status === "positive"
							? Labs.positive
							: traveler.examination.status}
					</p>
					<p>
						{traveler.examination.result_date
							? moment(
									traveler.examination.result_date,
									"DD-MM-YYYY HH:mm aa"
							  ).format("DD-MM-yyyy")
							: " "}
					</p>
					<p>
						{traveler.examination.result_date
							? moment(
									traveler.examination.result_date,
									"DD-MM-YYYY HH:mm aa"
							  ).format("HH:mm")
							: " "}
					</p>
				</td>

				{/* Actions */}
				<td scope="col">
					{traveler.examination.analysis_date &&
					!traveler.examination.result_date ? (
						<ShowForPermission permission="lab:submit-pcr-results">
							<button
								className="btn btn-success p-1"
								onClick={() => {
									setExaminationResult({
										...examinationResult,
										status: "",
										pcr_id: traveler.examination.id.toString(),
									});
									toggle();
								}}
							>
								<div className="d-flex align-items-center ">
									<p className="font-weight-bold">{groups.enterResult}</p>{" "}
									<i className="fa fa-check-circle m-1"></i>
								</div>
							</button>
						</ShowForPermission>
					) : traveler.examination.analysis_date &&
					  traveler.examination.result_date ? (
						<a
							className="btn btn-secondary p-1"
							href={`${attachment}${traveler.examination.attachment}`}
							target="_blank"
						>
							<div className="d-flex align-items-center ">
								<p className="font-weight-bold">{teamManagement.view}</p>{" "}
								<i className="fa fa-eye m-1"></i>
							</div>
						</a>
					) : (
						<ShowForPermission permission="lab:start-pcr-analysis">
							<button
								className="btn btn-outline-success p-1"
								onClick={() => {
									startAnalysis(traveler.examination.id);
								}}
							>
								<div className="d-flex align-items-center ">
									<p className="font-weight-bold">{Labs.ExaminationStart}</p>{" "}
									<i className="fa fa-check-circle m-1 "></i>
								</div>
							</button>
						</ShowForPermission>
					)}
				</td>
			</tr>
		</>
	);
}

export default Traveler;
