import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import Locale from "translations";

export default function TravelersNumber(reservations) {
	const { statistics } = Locale;
	const reservationsData = reservations.reservations;
	console.log(reservationsData);
	ChartJS.register(ArcElement, Tooltip, Legend);

	const data = {
		labels: [statistics.incoming, statistics.pending, statistics.checked],
		datasets: [
			{
				label: "",
				data: [
					reservationsData?.new,
					reservationsData?.pending,
					reservationsData?.checked,
				],
				backgroundColor: ["#24AEFB", "#F28F31", "#00B437"],
				borderColor: ["#24AEFB", "#F28F31", "#00B437"],
				borderWidth: 1,
			},
		],
	};

	return (
		<div className="HomeUsersContainer p-2 activity bg-white shadow-sm rounded">
			<div className="HomeUsersChar">
				<div className="title">
					<h2 className="font-weight-bold text-subtitle">
						{statistics.travelersNumber}
					</h2>
					<p class="text"></p>
				</div>
				<div className="HomeUsersContent">
					<div className="HomeUserStatistics">
						<Doughnut data={data} />
						<span className="HomeUserStatisticsTitle">
							{statistics.total_travelers}
						</span>
						<span className="HomeUserStatisticsCount">
							{reservationsData
								? reservationsData.checked +
								  reservationsData.new +
								  reservationsData.pending
								: null}
						</span>
					</div>
					<div className="HomeUserCount">
						<div className="HomeUserCountRow">
							<i
								className="fa fa-circle HomeUserCountIncomingIcon"
								aria-hidden="true"
							></i>
							<div className="HomeUserCountContent">
								<span className="HomeUserCountContentTitle">
									{statistics.incoming}
								</span>
								<span className="HomeUserCountContentNumber">
									{reservationsData?.new}
								</span>
							</div>
						</div>

						<div className="HomeUserCountRow">
							<i
								className="fa fa-circle HomeUserCountRunningIcon"
								aria-hidden="true"
							></i>
							<div className="HomeUserCountContent">
								<span className="HomeUserCountContentTitle">
									{statistics.pending}
								</span>
								<span className="HomeUserCountContentNumber">
									{reservationsData?.pending}
								</span>
							</div>
						</div>

						<div className="HomeUserCountRow">
							<i
								className="fa fa-circle HomeUserCountCheckedIcon"
								aria-hidden="true"
							></i>
							<div className="HomeUserCountContent">
								<span className="HomeUserCountContentTitle">
									{statistics.checked}
								</span>
								<span className="HomeUserCountContentNumber">
									{reservationsData?.checked}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
