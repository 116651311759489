import Pagination from "components/shared/Pagination";
import SelectField from "components/shared/SelectField";
import React, { useState } from "react";
import { store } from "react-notifications-component";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { savePCRResultSaudi } from "services/lab";
import Locale from "translations";
import Traveler from "./Traveler";

function ListTravelers({
	allTravelers,
	setAllTravelers,
	meta,
	filter,
	setfilter,
}) {
	const { groups, Labs, teamManagement } = Locale;
	const [modal, setModal] = useState(false);
	const [examinationResult, setExaminationResult] = useState({});

	const toggle = () => {
		setModal(!modal);
	};

	const statues = [
		{ value: "positive", label: Labs.positive },
		{ value: "negative", label: Labs.negative },
	];

	const goTo = async (page) => {
		setfilter({ ...filter, page: page });
	};

	/* save result */
	async function saveResult() {
		const formData = new FormData();
		for (let key in examinationResult) {
			formData.append(key, examinationResult[key]);
		}
		console.log(examinationResult, "examination result");
		console.log(formData);
		const Pcr = await savePCRResultSaudi(formData);
		if (Pcr.status === 201) {
			store.addNotification({
				title: "info!",
				message: Pcr.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}

		setfilter({ ...filter });
		toggle();
	}
	const Alltravelers =
		allTravelers &&
		allTravelers.length > 0 &&
		allTravelers.map((hotel, index) =>
			hotel.Travellers.map((travellers) => {
				return (
					<Traveler
						hotel={hotel}
						travellers={travellers}
						key={index}
						toggle={toggle}
						setExaminationResult={setExaminationResult}
						examinationResult={examinationResult}
					/>
				);
			})
		);

	return (
		<>
			<div className="medical-result">
				<div className="TeamManagement-header">
					<div className="TeamManagement-title">{Labs.travelers}</div>
				</div>

				<div className="reservation-table">
					<table className="table table-striped mb-0">
						<thead>
							<tr className="bg-grey text-white">
								<th scope="col">{teamManagement.region}</th>
								<th scope="col">{teamManagement.hotel}</th>
								<th scope="col">{teamManagement.dateInOut}</th>
								<th scope="col">{Labs.supervisor}</th>
								<th scope="col">{Labs.passportNumber}</th>
								<th scope="col">{Labs.pilgrimName}</th>
								<th scope="col">{Labs.nationalId}</th>
								<th scope="col">{Labs.gender}</th>
								<th scope="col">{Labs.date_of_birth}</th>
								<th scope="col">{Labs.processes}</th>
							</tr>
						</thead>
						<tbody>
							{Alltravelers && Alltravelers.length > 0 ? (
								Alltravelers
							) : (
								<tr>
									<td colSpan="11">
										<div className="product-build__product-no-data">
											<i className="fas fa-info-circle fa-lg"></i>{" "}
											<h4>{Labs.noResult}</h4>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
				<Pagination info={meta} goTo={goTo} items={10} />
				{/* Modal for view PCR result */}

				<div>
					<Modal isOpen={modal} toggle={toggle}>
						<ModalHeader toggle={toggle}>
							<p className="font-weight-bold">{groups.enterResult}</p>
						</ModalHeader>
						<ModalBody>
							<SelectField
								label={Labs.statusCheck}
								value={{
									value: examinationResult.status,
									label: examinationResult.status,
								}}
								placeholder={Labs.statusCheckPlaceholder}
								name="status"
								options={statues}
								onChange={(e) => {
									setExaminationResult({
										...examinationResult,
										status: e.value,
									});
								}}
							/>

							<input
								type="file"
								required
								accept="application/pdf, .pdf"
								name="file"
								id="file"
								className="my-4"
								onChange={(e) => {
									setExaminationResult({
										...examinationResult,
										file: e.target.files[0],
									});
								}}
							/>
						</ModalBody>
						<ModalFooter>
							<Button
								color="success"
								onClick={() => {
									saveResult();
								}}
							>
								{" "}
								{Labs.saveResult}
							</Button>{" "}
							<Button color="secondary" onClick={toggle}>
								{Labs.back}
							</Button>
						</ModalFooter>
					</Modal>
				</div>
			</div>
		</>
	);
}

export default ListTravelers;
