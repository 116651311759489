import moment from "moment";
import React, { useEffect, useState } from "react";
import { travelerPCRLabsCheckedSaudi } from "services/lab";
import Locale from "translations";
import Filter from "./Filter";
import ListCheckedTravelers from "./ListCheckedTravelers";

function PcrReservationCheckedSaudi() {
	const { teamManagement, Labs } = Locale;
	const [allTravelers, setAllTravelers] = useState({});
	const [meta, setmeta] = useState();

	const [filterData, setFilterData] = useState({
		hotel_id: "",
		name: "",
		national_id: "",
		passport_number: "",
		date: "",
	});

	/* get travelers list */
	useEffect(() => {
		async function fetchDataFilter() {
			const Pcr = await travelerPCRLabsCheckedSaudi({
				...filterData,
				hotel_id: filterData.hotel_id?.value,
				date: filterData.date
					? moment(filterData.date).format("DD-MM-YYYY")
					: "",
			});

			setAllTravelers(Pcr.data.data);
			setmeta(Pcr.data.meta);
		}

		const filterHandler = setTimeout(() => {
			fetchDataFilter();
		}, 500);

		return () => {
			clearTimeout(filterHandler);
		};
	}, [filterData]);

	return (
		<>
			<section className="main-color medical-section">
				<div className="container-fluid  min-Height">
					<Filter filterData={filterData} setFilterData={setFilterData} />
					<ListCheckedTravelers
						allTravelers={allTravelers}
						setAllTravelers={setAllTravelers}
						meta={meta}
						filter={filterData}
						setfilter={setFilterData}
					/>
				</div>
			</section>
		</>
	);
}

export default PcrReservationCheckedSaudi;
