import moment from "moment";
import React from "react";
import Locale from "translations";

function Traveler({ traveler, index }) {
	const locale = localStorage.getItem("currentLocale") || "en";
	const { groups, Labs, teamManagement } = Locale;
	return (
		<>
			<tr>
				<td scope="col">{traveler.traveller_name}</td>
				<td scope="col">{traveler.passport_num}</td>
				<td scope="col">{traveler.pcr_reservation?.examination?.lab?.name}</td>
				<td scope="col">{traveler.nin}</td>
				<td scope="col">{traveler.gender}</td>
				<td scope="col">{traveler.date_of_birth}</td>
				<td scope="col">
					{traveler.pcr_reservation?.examination_date ? (
						<React.Fragment>
							<p>
								{moment(
									traveler.pcr_reservation?.examination_date,
									"DD-MM-YYYY HH:mm aa"
								).format("DD-MM-yyyy")}
							</p>
							<p>
								{moment(
									traveler.pcr_reservation?.examination_date,
									"DD-MM-YYYY HH:mm aa"
								).format("HH:mm")}
							</p>
						</React.Fragment>
					) : (
						<i className="fa fa-window-minimize text-secondary"></i>
					)}
				</td>
				<td scope="col">
					{traveler.pcr_reservation?.examination?.analysis_date ? (
						<div>
							<p className="text-success font-weight-bold">
								{Labs.ExaminationDone}
							</p>
							{moment(
								traveler.pcr_reservation?.examination?.analysis_date,
								"DD-MM-YYYY HH:mm aa"
							).format("DD-MM-yyyy")}
						</div>
					) : (
						<i className="fa fa-window-minimize text-secondary"></i>
					)}

					<p>
						{traveler.pcr_reservation?.examination?.analysis_date
							? moment(
									traveler.pcr_reservation?.examination.analysis_date,
									"DD-MM-YYYY HH:mm aa"
							  ).format("HH:mm")
							: " "}
					</p>
				</td>
				<td scope="col">
					{traveler.pcr_reservation?.examination?.status ? (
						<React.Fragment>
							<p
								className={`${
									traveler.pcr_reservation?.examination?.status === "negative"
										? "text-success font-weight-bold"
										: traveler.pcr_reservation?.examination?.status ===
										  "positive"
										? "text-danger font-weight-bold"
										: "text-secondary "
								}`}
							>
								{traveler.pcr_reservation?.examination?.status}
							</p>
							<p>
								{traveler.pcr_reservation?.examination?.result_date
									? moment(
											traveler.pcr_reservation?.examination.result_date,
											"DD-MM-YYYY HH:mm aa"
									  ).format("DD-MM-yyyy")
									: " "}
							</p>
						</React.Fragment>
					) : (
						<i className="fa fa-window-minimize text-secondary"></i>
					)}
				</td>
				<td scope="col">
					<p
						className={`${
							traveler.sa_pcr_reservation?.status === "negative"
								? "text-success font-weight-bold"
								: traveler.sa_pcr_reservation?.status === "positive"
								? "text-danger font-weight-bold"
								: "text-secondary "
						}`}
					>
						{traveler.sa_pcr_reservation?.status}
					</p>
					<p>
						{traveler.sa_pcr_reservation?.result_date
							? moment(
									traveler.sa_pcr_reservation?.result_date,
									"DD-MM-YYYY HH:mm aa"
							  ).format("DD-MM-yyyy")
							: " "}
					</p>
				</td>
			</tr>
		</>
	);
}

export default Traveler;
