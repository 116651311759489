import axios from "axios";
import { cleanEmpty } from "./general";
const baseURL = process.env.REACT_APP_API_URL;
const [protocol, domain] = baseURL.split("//");

const host = window.location.host;
const subdomain = host != null ? host.split(".")[0] : null;
const HttpURL = process.env.REACT_APP_Http;
export const tenantURL = `${HttpURL}://${subdomain}.${domain}/api/tenant`;

const URL = `https://${subdomain}.${domain}/api`;

export const attachment = `https://${subdomain}.${domain}/`;

export const listTravellers = async () => {
	// console.log("tenantURL", tenantURL);
	return await axios
		.get(tenantURL + "/travellers/list")
		.then((res) => res)
		.catch((err) => err.response);
};

export const createLab = async (data) => {
	return await axios
		.post(`${tenantURL}/companies/labs`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editLab = async (data, id) => {
	return await axios
		.put(`${tenantURL}/companies/labs/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getLab = async (id) => {
	return await axios
		.get(`${tenantURL}/companies/labs/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const listLabs = async (page = 1) => {
	return await axios
		.get(`${tenantURL}/companies/labs?page=${page}`)
		.then((res) => res)
		.catch((err) => err.response);
};

/******* list lab users (company side) ******/
export const listLabsUsers = async (id, page = 1) => {
	return await axios
		.get(`${tenantURL}/companies/labs/${id}/users?page=${page}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listLabsUsersFilter = async (id, filter) => {
	return await axios
		.get(`${tenantURL}/companies/labs/${id}/users?`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const LabsUser = async (labId, userId) => {
	return await axios
		.get(`${tenantURL}/companies/labs/${labId}/users/${userId}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const changeLabUserInfo = async (labId, userId, changes) => {
	return await axios
		.put(`${tenantURL}/companies/labs/${labId}/users/${userId}`, changes)
		.then((res) => res)
		.catch((err) => err.response);
};

export const changePasswordUserForLab = async (labId, userId, pass) => {
	return await axios
		.patch(
			`${tenantURL}/companies/labs/${labId}/users/${userId}/change-password`,
			pass
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const changeCompanyPassword = async (password) => {
	return await axios
		.post(`${tenantURL}/auth/password`, password)
		.then((res) => res)
		.catch((err) => err.response);
};

export const changeStatusLabUser = async (labId, userId, status) => {
	return await axios
		.patch(`${tenantURL}/companies/labs/${labId}/users/${userId}/${status}`)
		.then((res) => res)
		.catch((err) => err.response);
};

/**** Labs *****/
export const travelerLabs = async (filter) => {
	// console.log("tenantURL", tenantURL);
	return await axios
		.get(`${tenantURL}/labs/insurance-policies`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const changeStatusTraveller = async (amount) => {
	return await axios
		.post(`${tenantURL}/labs/insurance-policies/requests-approve`, amount)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getCurrentUser = async (id) => {
	return await axios
		.get(`${tenantURL}/auth/me`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const branchesList = async (params) => {
	return await axios
		.get(`${tenantURL}/labs/branches`, { params })
		.then((res) => res)
		.catch((err) => err.response);
};

export const createBranch = async (data) => {
	return await axios
		.post(`${tenantURL}/labs/branches`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editBranch = async (data, id) => {
	return await axios
		.put(`${tenantURL}/labs/branches/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getBranch = async (id) => {
	return await axios
		.get(`${tenantURL}/labs/branches/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const activateBranch = async (id, status) => {
	return await axios
		.patch(`${tenantURL}/labs/branches/${id}/${status}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listUsersForBranch = async (id) => {
	return await axios
		.get(`${tenantURL}/labs/branches/${id}/users`)
		.then((res) => res)
		.catch((err) => err.response);
};

// export const deleteBranch = async (id) => {
// 	return await axios
// 		.delete(`${tenantURL}/labs/branches/${id}`)
// 		.then((res) => res)
// 		.catch((err) => err.response);
// };

export const listUsers = async (page = 1) => {
	return await axios
		.get(`${tenantURL}/labs/users?page=${page}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const changeStatusLabApi = async (id, status) => {
	return await axios
		.patch(`${tenantURL}/companies/labs/${id}/${status}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const changePassWordLabApi = async (id, status) => {
	return await axios
		.put(`${tenantURL}/companies/labs/${id}/change-password`, status)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editUser = async (data, id) => {
	return await axios
		.get(`${tenantURL}/labs/users${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const filterLabs = async (filter) => {
	return await axios
		.get(`${tenantURL}/companies/labs`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const filtetTravelers = async (filter) => {
	return await axios
		.get(`${tenantURL}/companies/insurance-policies`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

/* PCR Reservation  Lab*/
export const travelerPCRLabs = async (filter) => {
	return await axios
		.get(`${tenantURL}/labs/covid`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const startPCRAnalysis = async (id) => {
	return await axios
		.get(`${tenantURL}/labs/covid/start-analysis/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const savePCRResult = async (result) => {
	return await axios
		.post(`${tenantURL}/labs/covid/save-result`, result)
		.then((res) => res)
		.catch((err) => err.response);
};

export const travelerPCRLabsSaudi = async (filter) => {
	return await axios
		.get(`${tenantURL}/labs/covid/pilgrims-pcr-sa`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const travelerPCRLabsCheckedSaudi = async (filter) => {
	return await axios
		.get(`${tenantURL}/labs/covid/sa-pcrs`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const savePCRResultSaudi = async (result) => {
	return await axios
		.post(`${tenantURL}/labs/covid/sa-save-result`, result)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getAllHotelsPcr = async (id) => {
	return await axios
		.get(`${tenantURL}/labs/covid/get-all-hotels?area_id=${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getDashboardStatistics = async () => {
	return await axios
		.get(`${tenantURL}/dashboard`)
		.then((res) => res.data)
		.catch((err) => err.response);
};
