import React from "react";
import { Redirect, Route } from "react-router-dom";

// React Component
export default function CompaniesRoute({ component: Component, ...props }) {
	const isAuth = localStorage.getItem("isAuth");
	const userType = localStorage.getItem("userType");
	if (
		(isAuth && userType === "company-super-admin") ||
		(isAuth && userType === "company-user")
	) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					// <DashboardLayout>
					<Component {...matchProps} />
					// </DashboardLayout>
				)}
			/>
		);
	} else {
		return <Redirect to="/auth/login" />;
	}
}
