import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import React from "react";
import Locale from "translations";
function Filter({ filter, setfilter }) {
	const { teamManagement, Labs, commons } = Locale;
	const statuesCheck = [
		{ value: "positive", label: Labs.positive },
		{ value: "negative", label: Labs.negative },
	];

	const statues = [
		{ value: "true", label: Labs.status_checked },
		{ value: "false", label: Labs.status_notChecked },
	];
	return (
		<>
			<div className="row align-items-center m-0">
				<div className="col-md-2">
					<TextField
						type="text"
						placeholder={Labs.nationalIdPlaceholder}
						label={Labs.nationalId}
						value={filter.national_id}
						onChange={(e) => {
							setfilter({
								...filter,
								national_id: e.target.value,
							});
						}}
					/>
				</div>

				<div className="col-md-2">
					<TextField
						type="text"
						placeholder={Labs.passportNumberPlaceHolder}
						label={Labs.passportNumber}
						value={filter.passport_number}
						onChange={(e) => {
							setfilter({
								...filter,
								passport_number: e.target.value,
							});
						}}
					/>
				</div>

				<div className="col-md-1">
					<SelectField
						label={Labs.statusCheck}
						value={filter.status}
						placeholder={Labs.statusCheckPlaceholder}
						name="statuesCheck"
						options={statuesCheck}
						onChange={(e) => {
							setfilter({
								...filter,
								status: e,
								page: 1,
							});
						}}
					/>
				</div>
				<div className="col-md-2">
					<DatePickerField
						label={Labs.ExaminationDate}
						placeholder="YY-MM-DD"
						date={filter.examination_date}
						onDateChange={(e) => {
							// moment(e._d).format("DD-MM-YYYY")
							setfilter({
								...filter,
								examination_date: e,
								page: 1,
							});
						}}
						isOutsideRange={(day) => false}
					/>
				</div>

				<div className="col-md-2">
					<DatePickerField
						label={Labs.analysisDate}
						placeholder="YY-MM-DD"
						date={filter.analysis_date}
						onDateChange={(e) => {
							// moment(e._d).format("DD-MM-YYYY")
							setfilter({
								...filter,
								analysis_date: e,
								page: 1,
							});
						}}
						isOutsideRange={(day) => false}
					/>
				</div>

				<div className="col-md-1">
					<SelectField
						label={Labs.status}
						value={filter.checked}
						placeholder={Labs.statusPlaceholder}
						name="status"
						options={statues}
						onChange={(e) => {
							setfilter({
								...filter,
								checked: e,
								page: 1,
							});
						}}
					/>
				</div>

				{/* 				<div className="col-md-1">
					<button className=" btn-dark w-100 p-2 font-weight-bold  rounded ">
						{teamManagement.search}
					</button>
				</div> */}

				<div className="col-md-1" style={{ cursor: "pointer" }}>
					<i class="fas fa-retweet p-2"></i>
					<span
						className="font-weight-bold "
						onClick={() =>
							setfilter({
								national_id: "",
								passport_number: "",
								status: null,
								ExaminationDate: "",
								analysisDate: "",
							})
						}
					>
						{commons.reset}
					</span>
				</div>
			</div>
		</>
	);
}

export default Filter;
