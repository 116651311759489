import Pagination from "components/shared/Pagination";
import SelectField from "components/shared/SelectField";
import React, { useState } from "react";
import { store } from "react-notifications-component";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { savePCRResult, startPCRAnalysis } from "services/lab";
import Locale from "translations";
import Traveler from "./Traveler";

function ListTravelers({ travelers, settravelers, meta, filter, setfilter }) {
	const { groups, Labs } = Locale;
	const [modal, setModal] = useState(false);
	const [examinationResult, setExaminationResult] = useState({});

	const toggle = () => {
		setModal(!modal);
	};

	const statues = [
		{ value: "positive", label: Labs.positive },
		{ value: "negative", label: Labs.negative },
	];

	const goTo = async (page) => {
		setfilter({ ...filter, page: page });
	};

	/*start pcr analysis  */
	async function startAnalysis(id) {
		const Pcr = await startPCRAnalysis(id);
		setfilter({ ...filter });
	}

	/* save result */
	async function saveResult() {
		const formData = new FormData();
		for (let key in examinationResult) {
			formData.append(key, examinationResult[key]);
		}

		const Pcr = await savePCRResult(formData);
		if (Pcr.status === 201) {
			toggle();
			store.addNotification({
				title: "info!",
				message: Pcr.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}

		setfilter({ ...filter });
	}

	const Alltravelers =
		travelers &&
		travelers.length > 0 &&
		travelers.map((traveler, index) => (
			<Traveler
				traveler={traveler}
				key={index}
				toggle={toggle}
				startAnalysis={startAnalysis}
				setExaminationResult={setExaminationResult}
				examinationResult={examinationResult}
			/>
		));

	return (
		<>
			<div className="medical-result">
				<div className="TeamManagement-header">
					<div className="TeamManagement-title">{Labs.travelers}</div>
				</div>

				<div className="reservation-table">
					<table className="table table-striped mb-0">
						<thead>
							<tr className="bg-grey text-white">
								<th scope="col">{Labs.pilgrimName}</th>
								<th scope="col">{Labs.passportNumber}</th>
								<th scope="col">{Labs.nationalId}</th>
								<th scope="col">{Labs.gender}</th>
								<th scope="col">{Labs.date_of_birth}</th>
								<th scope="col">{Labs.Date_time_booking}</th>
								<th scope="col">{Labs.analysisDate}</th>
								<th scope="col">{Labs.analysisResults}</th>
								<th scope="col">{Labs.processes}</th>
							</tr>
						</thead>
						<tbody>
							{Alltravelers && Alltravelers.length > 0 ? (
								Alltravelers
							) : (
								<tr>
									<td colSpan="11">
										<div className="product-build__product-no-data">
											<i className="fas fa-info-circle fa-lg"></i>{" "}
											<h4>{Labs.noResult}</h4>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
				<Pagination info={meta} goTo={goTo} items={10} />
				{/* Modal for view PCR result */}

				<div>
					<Modal isOpen={modal} toggle={toggle}>
						<ModalHeader toggle={toggle}>
							<p className="font-weight-bold">{groups.enterResult}</p>
						</ModalHeader>
						<ModalBody>
							<SelectField
								label={Labs.statusCheck}
								value={{
									value: examinationResult.status,
									label: examinationResult.status,
								}}
								placeholder={Labs.statusCheckPlaceholder}
								name="status"
								options={statues}
								onChange={(e) => {
									setExaminationResult({
										...examinationResult,
										status: e.value,
									});
								}}
							/>

							<input
								type="file"
								accept="application/pdf, .pdf"
								name="file"
								id="file"
								className="my-4"
								onChange={(e) => {
									setExaminationResult({
										...examinationResult,
										file: e.target.files[0],
									});
								}}
							/>
						</ModalBody>
						<ModalFooter>
							<Button
								color="success"
								onClick={() => {
									saveResult();
								}}
							>
								{" "}
								{Labs.saveResult}
							</Button>{" "}
							<Button color="secondary" onClick={toggle}>
								{Labs.back}
							</Button>
						</ModalFooter>
					</Modal>
				</div>
			</div>
		</>
	);
}

export default ListTravelers;
