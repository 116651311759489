const locale = localStorage.getItem("currentLocale") || "en";

export function formatPostLab(lab) {
	const {
		name,
		capacity,
		fromHour,
		toHour,
		country,
		city,
		area,
		address,
		email,
		phone,
		userName,
		userEmail,
		userPhone,
		workDays,
		userId,
	} = lab;

	return {
		name,
		capacity,
		from_hour: fromHour,
		to_hour: toHour,
		email,
		phone_number: `+${country.value}${phone}`,
		country_id: country.value,
		city_id: city.value,
		area_id: area.value,
		user_name: userName,
		user_email: userEmail,
		user_phone_number: `+${country.value}${phone}`,
		address,
		user_id: userId,
		work_days: workDays,
	};
}

export function formatGetLab(lab) {
	const {
		name,
		email,
		phone_number,
		country,
		city,
		area,
		user,
		address,
		time_sheet,
	} = lab;
	// debugger;
	// const [_, phone] = phone_number.split(country_id);
	// const [__, userPhone] = user.phone_number.split(country_id);

	return {
		name: name ? name : "",
		email,
		phone_number,
		country: { value: country.id, label: country.names[locale] },
		city: { value: city.id, label: city.names[locale] },
		area: { value: area?.id, label: area?.names[locale] },
		address,
		userName: user.name,
		userEmail: user.email,
		phone: phone_number.slice(3),
		userId: user.id,
		workDays: time_sheet.work_days,
		capacity: time_sheet.capacity,
		fromHour: time_sheet.from_hour,
		toHour: time_sheet.to_hour,
	};
}

export function formatPostBranch(branch) {
	const { branchName, email, country, city, area, address, phone } = branch;
	console.log(branch, "BRANCH");
	return {
		name: branchName,
		email,
		phone,
		country_id: country.value,
		city_id: city.value,
		area_id: area.value,
		address,
		phone_number: `+${country.value}${phone}`,
	};
}

export function formatGetBranch(branch) {
	const {
		name,
		email,
		country,
		city,
		area,
		address,
		phone_number,
		country_id,
		id,
	} = branch;
	const [_, phone] = phone_number.split(country_id);

	console.log("BRANCH", branch);

	return {
		branchName: name,
		email,
		phone,
		country: { value: country.id, label: country.names[locale] },
		city: { value: city.id, label: city.names[locale] },
		area: { value: area.id, label: area.names[locale] },
		address,
		country_id: country.value,
		id,
	};
}

export function formatPostUser(branch) {
	const { name, email } = branch;

	return {
		name,
		email,
	};
}
