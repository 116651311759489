import Pagination from "components/shared/Pagination";
import React, { useEffect, useState } from "react";
import { filterLabs, listLabs } from "services/lab";
import Locale from "translations";
import Filter from "./Filter";
import List from "./List";
export default function TeamManagement() {
	const { teamManagement, Labs } = Locale;
	const [active, setActive] = useState(1);
	const [labs, setLabs] = useState([]);
	const [res, setRes] = useState([]);
	const [filter, setfilter] = useState({
		name: "",
		country_id: null,
		city_id: null,
		status: null,
	});

	useEffect(() => {
		async function fetchData() {
			const labs = await listLabs();
			if (labs.status === 200) {
				setLabs(labs.data.data);
				let meta = labs.data.meta;
				setRes(meta);
			}
		}
		fetchData();
	}, []);

	//when user change filter input
	useEffect(() => {
		async function fetchData() {
			const x = await filterLabs({
				...filter,
				status: filter.status?.value,
				page: filter.page,
			});
			setLabs(x.data.data);
			setRes(x.data.meta);
		}

		const filterHandler = setTimeout(() => {
			fetchData();
		}, 500);

		return () => {
			clearTimeout(filterHandler);
		};
	}, [filter]);

	// pagination
	const goTo = async (page) => {
		setfilter({ ...filter, page: page });
	};

	return (
		<>
			<section className=" py-3 medical-section Users">
				<div className="container-fluid min-Height">
					<div className="medical-result">
						<section className="reservation-section">
							<div className="reservation-box m-0">
								<div className="TeamManagement-header">
									<div className="TeamManagement-title">{Labs.labs}</div>
								</div>
								<Filter
									setfilter={setfilter}
									filter={filter}
									setLabs={setLabs}
									activePage={active}
								/>

								<List
									filter={filter}
									labs={labs}
									setMeta={setRes}
									meta={res}
									setLabs={setLabs}
									setfilter={setfilter}
								/>

								<Pagination info={res} goTo={goTo} items={10} />
							</div>
						</section>
					</div>
				</div>
			</section>
		</>
	);
}
