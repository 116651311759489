import React, { useState } from "react";
import Locale from "translations";
import Filter from "./Filter";
import ListGroups from "./ListGroups";
import ListUsers from "./ListUsers";
export default function TeamManagementBranches() {
	const { teamManagement } = Locale;
	const [active, setActive] = useState(1);

	return (
		<>
			<section className="bg-gray py-3 medical-section Users">
				<div className="container-fluid min-Height">
					<div className="col-md-12">
						<div className="row">
							<div className="itemBoxCircle">
								<div className="boxCircle">
									<p>
										<span className="counter">6</span>
										<span className="circle">
											<i className="fas fa-circle"></i>
										</span>
										{teamManagement.userCount}
									</p>
								</div>
							</div>
							<div className="itemBoxCircle">
								<div className="boxCircle">
									<p>
										<span className="counter green">6</span>
										<span className="circle green">
											<i className="fas fa-circle"></i>
										</span>
										{teamManagement.activationUsers}
									</p>
								</div>
							</div>
							<div className="itemBoxCircle">
								<div className="boxCircle">
									<p>
										<span className="counter red">0</span>
										<span className="circle red">
											<i className="fas fa-circle"></i>
										</span>
										{teamManagement.inactivateUsers}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="medical-result">
						<section className="reservation-section">
							<div className="reservation-box m-0">
								<div className="TeamManagement-header">
									<div className="TeamManagement-title">
										{" "}
										{teamManagement.teamManagement}
									</div>
									<div className="TeamManagement-taps">
										<button
											onClick={() => {
												setActive(1);
											}}
											className={active === 1 ? "active" : ""}
										>
											{teamManagement.users}
										</button>
										<button
											className={active === 2 ? "active" : ""}
											onClick={() => {
												setActive(2);
											}}
										>
											{" "}
											{teamManagement.groups}
										</button>
									</div>
								</div>

								<Filter activePage={active} />
								{active === 1 ? <ListUsers /> : <ListGroups />}
							</div>
						</section>
					</div>
				</div>
			</section>
		</>
	);
}
