import Pagination from "components/shared/Pagination";
import ListUsers from "modules/companies/labManagement/ListUser/ListUsers";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { listLabsUsers, listLabsUsersFilter } from "services/lab";
import Locale from "translations";
import Filter from "./Filter";
function Index() {
	const { id } = useParams();
	const [Users, setUsers] = useState();
	const { Labs } = Locale;
	const [meta, setmeta] = useState();
	const [filter, setfilter] = useState({
		name: "",
		status: null,
	});
	useEffect(() => {
		async function fetchData() {
			const users = await listLabsUsers(id);
			if (users.status === 200) {
				setUsers(users.data.data.data);
				/* Convert data to meta */
				const meta = Object.entries(users.data.data).filter((key) => {
					return key[0] != "data";
				});
				const info = meta.reduce((acc, item) => {
					acc[item[0]] = item[1];
					return acc;
				}, {});

				setmeta(info);
			}
		}
		fetchData();
	}, []);

	//When user change filter input
	useEffect(() => {
		async function fetchData() {
			const x = await listLabsUsersFilter(id, {
				...filter,
				status: filter.status?.value,
				page: filter.page,
			});
			setUsers(x.data.data.data);
			/* 			setmeta(x.data.meta); */
		}

		const filterHandler = setTimeout(() => {
			fetchData();
		}, 500);
		return () => {
			clearTimeout(filterHandler);
		};
	}, [filter]);

	return (
		<>
			<section className=" py-3 medical-section Users">
				<div className="container-fluid min-Height">
					<div className="medical-result">
						<section className="reservation-section">
							<div className="reservation-box m-0">
								<div className="TeamManagement-header">
									<div className="TeamManagement-title">{Labs.users}</div>
								</div>
								<Filter filter={filter} setfilter={setfilter} />
								{Users && (
									<ListUsers Users={Users} labId={id} setUsers={setUsers} />
								)}

								<Pagination info={meta} /* goTo={goTo} */ items={10} />
							</div>
						</section>
					</div>
				</div>
			</section>
		</>
	);
}

export default Index;
