import logoImage from "assets/images/login/mainLogo.png";
import { useLabsDispatch, useLabsState } from "context/global";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { centralLogin } from "services/admin";
import { tenantLogin } from "services/tenant";
import Locale from "translations";
export default function Login() {
	const { loading } = useLabsState();
	const { login } = Locale;
	const host = window.location.host;
	const dispatch = useLabsDispatch();
	let subdomain = "";
	if (process.env.ENVIRONMENT != "production") {
		console.log("mesh prod");
		const preSubdomain = host.split(process.env.REACT_APP_FRONT_URL)[0];
		subdomain = preSubdomain.split(".")[0]; //host.split(".").length > 1 ? host.split(".")[0] : null;
	} else {
		console.log("PROD encv");
		const preSubdomain = host.split(process.env.REACT_APP_FRONT_URL)[0];
		subdomain = preSubdomain.split(".")[0]; // host.split(".").length > 3 ? host.split(".")[0] : null;
	}

	if (subdomain == "labs" || subdomain == "front") {
		subdomain = null;
	}
	const history = useHistory();

	const [user, setUser] = useState({
		email: "",
		password: "",
	});

	const submit = async () => {
		if (subdomain !== "") {
			const res = await tenantLogin(user, subdomain);

			if (res.status === 200) {
				dispatch({ type: "login", payload: res.data });

				if (res.data.user_type === "branch-super-admin") {
					history.push("/branches");
				} else if (
					res.data.user_type === "lab-super-admin" ||
					res.data.user_type === "lab-user"
				) {
					history.push("/lab");
				} else if (
					res.data.user_type === "company-super-admin" ||
					res.data.user_type === "company-user"
				) {
					history.push("/companies");
				} else {
					history.push("/");
				}
			}
		} else {
			const res = await centralLogin(user);
			if (res.status === 200) {
				dispatch({ type: "login", payload: res.data.data });
				history.push("/admin/");
			}
		}
	};

	return (
		<>
			<div className="login-page  mb-2">
				<div className="container">
					<div className="box-login d-flex">
						<div className="login-form w-75 m-auto">
							<div className="d-flex mb-4 justify-content-center align-items-center">
								<div>
									<img src={logoImage} className="img-fluid w-50" />
								</div>
							</div>

							<div className="form-group mb-3">
								<div className="input-group border-0 mb-2">
									<input
										className="form-control h-75 "
										placeholder={login.email}
										id="sys-log-email"
										value={user.email}
										onChange={(e) =>
											setUser({ ...user, email: e.target.value })
										}
									/>
									<div className="input-group-prepend">
										<div className="input-group-text">
											<i className="fas fa-user-circle"></i>
										</div>
									</div>
								</div>
							</div>
							<div className="form-group mb-3">
								<div className="input-group border-0 mb-2">
									<input
										className="form-control h-75"
										type="password"
										placeholder={login.password}
										id="sys-log-password"
										value={user.password}
										onChange={(e) =>
											setUser({ ...user, password: e.target.value })
										}
									/>
									<div className="input-group-prepend">
										<div className="input-group-text">
											<i className="fas fa-unlock-alt"></i>
										</div>
									</div>
								</div>
							</div>

							<div className="form-group">
								<button
									className="btn  btn-login btn-lg btn-block bg-blue text-white text-headline"
									id="sys-log-submit"
									onClick={submit}
								>
									{loading ? (
										<span>
											<i className="fas fa-spinner fa-spin"></i>{" "}
											{login.verificationProgress} ...
										</span>
									) : (
										<span>{login.login} </span>
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
