import React, { useEffect, useState } from "react";
import { getDashboardStatistics } from "services/lab";
import Activity from "./Activity";
import Counts from "./Counts";
import TravelersNumber from "./TravelersNumber";

export default function DashboardCompanies() {
	const [statistics, setStatistics] = useState();

	useEffect(() => {
		async function fetchData() {
			const allStatistics = await getDashboardStatistics();
			console.log(allStatistics);
			setStatistics(allStatistics);
		}
		fetchData();
	}, []);

	return (
		<>
			<section className="dashboard-companies">
				<div className="container-fluid min-Height">
					<div className="row px-5 pt-4">
						<div className="col-md-3">
							<Activity logsActivity={statistics?.logs} />
						</div>
						<div className="col-md-6">
							<TravelersNumber reservations={statistics?.reservations} />
						</div>
						<div className="col-md-3">
							<Counts labsNum={statistics?.labs} usersNum={statistics?.users} />
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
