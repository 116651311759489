import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import { attachment } from "services/lab";
import Locale from "translations";
function CheckedTraveler({ traveler }) {
	const locale = localStorage.getItem("currentLocale") || "en";
	const { groups, Labs, teamManagement } = Locale;
	const { lab_id } = useParams();

	return (
		<>
			<tr>
				<td scope="col">{traveler.hotel.area.name}</td>
				<td scope="col">{traveler.hotel.name}</td>
				<td scope="col">
					<p className="text-success font-weight-bold">
						{moment(traveler.hotel.EntryDate, "YYYY-MM-DD ").format(
							"DD-MM-yyyy"
						)}
					</p>
					<p className="text-danger font-weight-bold">
						{moment(traveler.hotel.ExitDate, "YYYY-MM-DD ").format(
							"DD-MM-yyyy"
						)}
					</p>
				</td>

				<td scope="col">
					<p>{traveler.SaSupervisor?.name}</p>
					<p>{traveler.SaSupervisor?.saudi_phone}</p>
					<p>{traveler.SaSupervisor?.egypt_phone}</p>
				</td>
				<td scope="col">{traveler.traveller.passport_num}</td>
				<td scope="col">{traveler.traveller.traveller_name}</td>
				<td scope="col">{traveler.traveller.Nin}</td>
				<td scope="col">{traveler.traveller.gender}</td>
				<td scope="col">{traveler.traveller.date_of_birth}</td>
				<td scope="col">
					<p
						className={`${
							traveler.examination.status === "negative"
								? "text-success font-weight-bold"
								: traveler.examination.status === "positive"
								? "text-danger font-weight-bold"
								: "text-secondary "
						}`}
					>
						{traveler.examination.status === "negative"
							? Labs.negative
							: traveler.examination.status === "positive"
							? Labs.positive
							: traveler.examination.status}
					</p>
					<p>
						{traveler.examination.result_date
							? moment(
									traveler.examination.result_date,
									"DD-MM-YYYY HH:mm aa"
							  ).format("DD-MM-yyyy")
							: " "}
					</p>
				</td>
				<td scope="col">
					<a
						className="btn btn-secondary p-1"
						href={`${attachment}${traveler.examination.attachment}`}
						target="_blank"
					>
						<div className="d-flex align-items-center ">
							<p className="font-weight-bold">{teamManagement.view}</p>{" "}
							<i className="fa fa-eye m-1"></i>
						</div>
					</a>
				</td>
			</tr>
		</>
	);
}

export default CheckedTraveler;
