import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL + "/api/tenant/auth/login";
const HttpURL = process.env.REACT_APP_Http;

export const tenantLogin = async (data, tenant) => {
	const [protocol, domain] = baseURL.split("//");

	// const tenantURL = `${protocol}//${tenant}.${domain}`;
	const tenantURL = `${HttpURL}://${tenant}.${domain}`;
	// console.log("tenantURL", tenantURL, data);

	return await axios
		.post(tenantURL, data)
		.then((res) => res)
		.catch((err) => err.response);
};
