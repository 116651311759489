import CheckBox from "components/shared/Chekbox";
import Pagination from "components/shared/Pagination";
import PasswordField from "components/shared/PasswordField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useLabsState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { fetchCountries } from "services/lookups";
import {
	changePasswordAPI,
	changeStatus,
	editGroupAPI,
	editUserAPI,
	getGroupData,
	getUserData,
	listGroups,
	listGroupsDp,
	listPermissions,
	listUsers
} from "services/teamManagementCompany";
import Locale from "translations";
import Filter from "./Filter";
import ListGroups from "./ListGroups";
import ListUsers from "./ListUsers";

export default function TeamManagementCompanies() {
	const { commons } = Locale;
	const { teamManagement } = Locale;
	const [active, setActive] = useState(1);
	const [groupsDp, setgroupsDp] = useState([]);
	const [selected, setSelected] = useState([]);
	let currentLocale = localStorage.getItem("currentLocale") ?? "en";
	const [Permissions, setPermissions] = useState({ selected: [] });
	const [group, setGroup] = useState({});
	const [users, setUsers] = useState([]);
	const [groups, setGroups] = useState([]);
	const [userid, setUserId] = useState(0);
	const [groupId, setGroupId] = useState(0);
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [user, setUser] = useState({});
	const [countries, setCountries] = useState([]);
	const [modalEdit, setModalEdit] = useState(false);
	const [changePassword, setChangePassword] = useState({});
	const [filter, setfilter] = useState({});
	const locale = localStorage.getItem("currentLocale") || "en";
	let LabsState = useLabsState();

	const AllPermissions = Object.keys(Permissions).map((key) => {
		if (key != "selected") {
			return (
				<div className="col-md-3" key={`page-${key}`}>
					<div className="card-group">
						<div className="box-title">
							{/* <CheckBox /> */}
							<h3>{key}</h3>
						</div>

						{Permissions[key].map((Permission, index) => {
							return (
								<div className="box-row" key={key + index}>
									{" "}
									<CheckBox
										key={key + index}
										onChange={(e) => handleCheck(e)}
										value={Permission.id}
										text={Permission.display_name[currentLocale]}
										checked={Permissions?.selected?.includes(Permission.id)}
									/>{" "}
								</div>
							);
						})}
					</div>
				</div>
			);
		}
	});

	const handleCheck = (e) => {
		let check = e.target.checked;
		let select = [...Permissions.selected];
		console.log(e.target.checked, e.target.value);

		if (check) {
			select.push(+e.target.value);
		} else {
			let ind = select.indexOf(+e.target.value);
			select.splice(ind, 1);
		}
		setSelected(select);
		setPermissions({ ...Permissions, selected: select });
	};

	useEffect(() => {
		async function fetchData() {
			if (
				LabsState.permissions.includes("company:list-users") ||
				LabsState.userType.includes("super-admin")
			) {
				const usersData = await listUsers();
				if (usersData.status === 200) {
					setUsers(usersData.data);
				}
			}
			if (
				LabsState.permissions.includes("company:list-groups") ||
				LabsState.userType.includes("super-admin")
			) {
				const groupsData = await listGroups();
				if (groupsData.status === 200) {
					setGroups(groupsData.data);
				}
			}

			if (
				LabsState.permissions.includes("company:list-groups") &&
				LabsState.permissions.includes("company:list-users") === false
			) {
				setActive(2);
			}

			// const GroupsDp = await listGroupsDp();
			// if (GroupsDp.status === 200) {
			// 	const format = GroupsDp.data?.groups?.map((e) => ({
			// 		value: e.id,
			// 		label: e.name
			// 	}));
			// 	setgroupsDp(format);
			// }
		}
		fetchData();
	}, []);

	useEffect(() => {
		// async function fetchDp() {
		// 	const GroupsDp = await listGroupsDp();
		// 	if (GroupsDp.status === 200) {
		// 		const format = GroupsDp.data?.groups?.map((e) => ({
		// 			value: e.id,
		// 			label: e.name
		// 		}));
		// 		setgroupsDp(format);
		// 	}
		// }
		// fetchDp();
	}, [modalEdit]);

	const getNewData = async () => {
		const usersData = await listUsers();
		if (usersData.status === 200) {
			setUsers(usersData.data);
		}

		if (
			LabsState.permissions.includes("company:list-groups") ||
			LabsState.userType.includes("super-admin")
		) {
			const groupsData = await listGroups();
			if (groupsData.status === 200) {
				setGroups(groupsData.data);
			}
		}
	};

	const getNewDataGroups = async () => {
		const groupsData = await listGroups();
		if (groupsData.status === 200) {
			setGroups(groupsData.data);
		}
	};

	const [errors, setErrors] = useState({});
	const [modalPassword, setModalPassword] = useState(false);
	const togglePassword = (e) => {
		setChangePassword({});
		setUserId(e);
		setModalPassword(!modalPassword);
		setErrors({});
	};

	const [modalEditGroup, setModalEditGroup] = useState(false);
	useEffect(() => {
		async function fetchPre() {
			if (modalEditGroup != false) {
				const PermissionsData = await listPermissions();
				if (PermissionsData.status === 200) {
					setPermissions({ ...Permissions, ...PermissionsData.data });
				}
			}
		}

		fetchPre();
	}, [modalEditGroup]);
	const toggleEditGroup = async (e) => {
		setGroupId(e);
		if (e != 0 && !e.hasOwnProperty("type")) {
			const req = await getGroupData(e);
			if (req.status == 200) {
				setGroup({
					...req.data.data,
				});
				let newpermissions = req.data.data.permissions;
				let list = [];
				Object.keys(newpermissions).map((key) => {
					newpermissions[key].map((Permission) => {
						list.push(Permission.id);
					});
				});

				setPermissions({ ...Permissions, selected: list });
				setSelected(list);

				console.log(Permissions);
			}
		}
		setModalEditGroup(!modalEditGroup);
	};
	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCountries();
			const format = res.map((t) => ({
				value: t.id,
				label: t.names[locale],
			}));
			setCountries(format);
		}
		fetchLookups();
	}, []);

	const [isErrorLoadedEdit, setIsErrorLoadedEdit] = useState(false);
	const [isErrorLoadedEditGroup, setIsErrorLoadedEditGroup] = useState(false);

	const toggleEdit = async (e) => {
		setUserId(e);
		setModalEdit(!modalEdit);
		if (e != 0 && !e.hasOwnProperty("type")) {
			const req = await getUserData(e);

			if (req.status == 200) {
				setUser({
					...req.data.data,
					group_id: {
						value: req.data?.data?.roles[0]?.id,
						label: req.data?.data?.roles[0]?.display_name,
					},
					country: {
						label: req.data?.data?.country.names[currentLocale],
						value: req.data?.data?.country.id,
					},
				});
			}
		}

		const GroupsDp = await listGroupsDp();
		if (GroupsDp.status === 200) {
			const format = GroupsDp.data?.groups?.map((e) => ({
				value: e.id,
				label: e.name,
			}));
			setgroupsDp(format);
		}
	};

	const changeStatusUser = async (id, status) => {
		const res = await changeStatus(id, status);
		if (res.status === 200) {
			store.addNotification({
				title: "info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			const usersData = await listUsers(filter.page);
			if (usersData.status === 200) {
				setUsers(usersData.data);
			}

			// if (
			// 	LabsState.permissions.includes("company:list-groups") ||
			// 	LabsState.userType.includes("super-admin")
			// ) {
			// 	const groupsData = await listGroups();
			// 	debugger;
			// 	if (groupsData.status === 200) {
			// 		setGroups(groupsData.data);
			// 	}
			// }
		}
	};

	const isPasswordMatch =
		changePassword.password === changePassword.password_confirmation;

	const handlePassword = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const handleEdit = async () => {
		checkFormErrorsEdit();
		if (!isErrorLoadedEdit) {
			setIsErrorLoadedEdit(true);
		} else {
			setIsErrorLoadedEdit(false);
		}
	};

	useEffect(() => {
		const EditUser = async () => {
			if (isFormValid(errors)) {
				const res = await editUserAPI(userid, {
					...user,
					group_id: user.group_id.value,
				});
				if (res.status === 201) {
					toggleEdit(0);
					getNewData();
					store.addNotification({
						title: "info!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			}
		};

		EditUser();
	}, [isErrorLoadedEdit]);

	const checkFormErrorsEdit = () => {
		setErrors({
			...errors,
			...validate(
				{
					name: "name",
					value: user.name,
				},
				{
					required: true,
				}
			),
			...validate(
				{
					name: "email",
					value: user.email,
				},
				{
					required: true,
				}
			),
		});
	};

	const checkFormErrorsEditGroup = () => {
		setErrors({
			...errors,
			...validate(
				{
					name: "name",
					value: group.name,
				},
				{
					required: true,
				}
			),
		});
	};

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{
					name: "password",
					value: changePassword.password,
				},
				{
					required: true,
					password: true,
					min: 8,
				}
			),
		});
	};

	const handleEditGroup = () => {
		checkFormErrorsEditGroup();
		if (!isErrorLoadedEditGroup) {
			setIsErrorLoadedEditGroup(true);
		} else {
			setIsErrorLoadedEditGroup(false);
		}
	};

	useEffect(() => {
		const editGroup = async () => {
			if (isFormValid(errors)) {
				const res = await editGroupAPI(groupId, {
					name: group.name,
					permissions: selected,
				});
				if (res.status == 201) {
					toggleEditGroup(0);
					getNewDataGroups();
					store.addNotification({
						title: "info!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			}
		};

		editGroup();
	}, [isErrorLoadedEditGroup]);

	useEffect(() => {
		const changePasswordUser = async () => {
			if (isFormValid(errors)) {
				const res = await changePasswordAPI(userid, changePassword);
				if (res.status == 200) {
					togglePassword(0);
					store.addNotification({
						title: "info!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			}
		};

		changePasswordUser();
	}, [isErrorLoaded]);

	const goTo = async (page) => {
		setfilter({ ...filter, page: page });
		const usersData = await listUsers(page);
		if (usersData.status === 200) {
			setUsers(usersData.data);
		}
	};

	const goTogroups = async (page) => {
		const groupsData = await listGroups(page);
		if (groupsData.status === 200) {
			setGroups(groupsData.data);
		}
	};
	return (
		<>
			<section className="bg-gray py-3 medical-section Users">
				<div className="container-fluid min-Height">
					<div className="col-md-12">
						<div className="row">
							<div className="itemBoxCircle">
								{active === 1 ? (
									<div className="boxCircle">
										<p>
											<span className="counter">{users?.data?.length}</span>
											<span className="circle">
												<i className="fas fa-circle"></i>
											</span>
											{teamManagement.userCount}
										</p>
									</div>
								) : (
									<div className="boxCircle">
										<p>
											<span className="counter">{groups?.data?.length}</span>
											<span className="circle">
												<i className="fas fa-circle"></i>
											</span>
											{teamManagement.groupCount}
										</p>
									</div>
								)}
							</div>
							{/* <div className="itemBoxCircle">
								<div className="boxCircle">
									<p>
										<span className="counter green">6</span>
										<span className="circle green">
											<i className="fas fa-circle"></i>
										</span>
										{teamManagement.activationUsers}
									</p>
								</div>
							</div>
							<div className="itemBoxCircle">
								<div className="boxCircle">
									<p>
										<span className="counter red">0</span>
										<span className="circle red">
											<i className="fas fa-circle"></i>
										</span>
										{teamManagement.inactivateUsers}
									</p>
								</div>
							</div> */}
						</div>
					</div>

					<div className="medical-result ">
						<section className="reservation-section mb-5">
							<div className="reservation-box m-0">
								<div className="TeamManagement-header">
									<div className="TeamManagement-title">
										{" "}
										{teamManagement.teamManagement}
									</div>
									<div className="TeamManagement-taps">
										<ShowForPermission permission="company:list-users">
											<button
												onClick={() => {
													setActive(1);
												}}
												className={active === 1 ? "active" : ""}
											>
												{teamManagement.users}
											</button>
										</ShowForPermission>

										<ShowForPermission permission="company:list-groups">
											<button
												className={active === 2 ? "active" : ""}
												onClick={() => {
													setActive(2);
												}}
											>
												{" "}
												{teamManagement.groups}
											</button>
										</ShowForPermission>
									</div>
								</div>

								<Filter activePage={active} getNewData={getNewData} />
								{active === 1 ? (
									<ListUsers
										user={users}
										togglePassword={togglePassword}
										changeStatusUser={changeStatusUser}
										toggleEdit={toggleEdit}
									/>
								) : groups && groups?.data?.length > 0 ? (
									groups.data.map((res) => (
										<ListGroups group={res} toggleEditGroup={toggleEditGroup} />
									))
								) : (
									""
								)}
							</div>

							{active === 1 ? (
								users?.data?.length > 0 ? (
									<Pagination info={users?.meta} goTo={goTo} items={10} />
								) : null
							) : groups?.data?.length > 0 ? (
								<Pagination info={groups?.meta} goTo={goTogroups} items={10} />
							) : null}
						</section>

						<Modal isOpen={modalPassword} toggle={togglePassword}>
							<ModalHeader toggle={togglePassword}>
								{teamManagement.changePassword}
							</ModalHeader>
							<ModalBody>
								<div className="row">
									<div className="col-md-12">
										<PasswordField
											type="text"
											label={teamManagement.password}
											placeholder={teamManagement.password}
											name="password"
											onChange={(e) => {
												setErrors({
													...errors,
													...validate(e.target, {
														required: true,
														password: true,
														min: 8,
													}),
												});
												setChangePassword({
													...changePassword,
													password: e.target.value,
												});
											}}
											min={8}
											color={
												errors?.password?.required ||
												errors?.password?.password ||
												errors?.password?.min
													? "danger"
													: ""
											}
											errors={errors?.password}
											value={changePassword.password}
										/>
									</div>
									<div className="col-md-12">
										<PasswordField
											type="text"
											label={teamManagement.password_confirmation}
											placeholder={teamManagement.password_confirmation}
											name="password_confirmation"
											onChange={(e) => {
												setChangePassword({
													...changePassword,
													password_confirmation: e.target.value,
												});
											}}
											color={
												errors?.password_confirmation?.required ? "danger" : ""
											}
											errors={errors?.password_confirmation}
											value={changePassword.password_confirmation}
										/>
									</div>
								</div>
							</ModalBody>
							<ModalFooter>
								<Button color="primary" onClick={handlePassword}>
									{commons.submit}
								</Button>{" "}
								<Button color="secondary" onClick={togglePassword}>
									{commons.cancel}
								</Button>
							</ModalFooter>
						</Modal>

						<ShowForPermission permission="company:edit-user">
							<Modal isOpen={modalEdit} toggle={toggleEdit}>
								<ModalHeader toggle={toggleEdit}>
									{" "}
									{commons.editUser}
								</ModalHeader>
								<ModalBody>
									<div className="col-md-12">
										<div className="row">
											<TextField
												type="text"
												label={teamManagement.name}
												placeholder={teamManagement.name}
												name="name"
												onChange={(e) => {
													setErrors({
														...errors,
														...validate(e.target, {
															required: true,
														}),
													});
													setUser({
														...user,
														name: e.target.value,
													});
												}}
												color={errors?.name?.required ? "danger" : ""}
												errors={errors?.name}
												value={user.name}
											/>
										</div>
										<div className="row">
											<TextField
												type="text"
												label={teamManagement.email}
												placeholder={teamManagement.email}
												name="email"
												onChange={(e) => {
													setErrors({
														...errors,
														...validate(e.target, {
															required: true,
															email: true,
														}),
													});
													setUser({
														...user,
														email: e.target.value,
													});
												}}
												color={
													errors?.email?.required || errors?.email?.email
														? "danger"
														: ""
												}
												errors={errors?.email}
												value={user.email}
											/>
										</div>
										<div className="row">
											<SelectField
												type="text"
												name="country"
												label={teamManagement.country}
												placeholder={teamManagement.country}
												errors={errors.country}
												color={errors.country?.required ? "danger" : ""}
												options={countries}
												onChange={(e) => {
													setUser({
														...user,
														country: e,
													});
													setErrors({
														...errors,
														...validate(
															{ name: "country", value: e },
															{
																required: true,
															}
														),
													});
												}}
												value={user.country}
											/>
										</div>
										<div className="row">
											<TextField
												type="phone_number"
												label={teamManagement.phoneNumber}
												placeholder={teamManagement.phoneNumber}
												name="phone_number"
												onChange={(e) => {
													setErrors({
														...errors,
														...validate(e.target, {
															required: true,
														}),
													});
													setUser({
														...user,
														phone_number: e.target.value,
													});
												}}
												color={errors?.phone_number?.required ? "danger" : ""}
												errors={errors?.phone_number}
												value={user.phone_number}
											/>
										</div>
										<div className="row">
											<SelectField
												type="text"
												name="groups"
												label={teamManagement.groups}
												placeholder={teamManagement.groups}
												errors={errors.country}
												color={errors.country?.required ? "danger" : ""}
												options={groupsDp}
												onChange={(e) => {
													setUser({
														...user,
														group_id: e,
													});
												}}
												value={user.group_id}
											/>
										</div>
									</div>
								</ModalBody>
								<ModalFooter>
									<Button color="primary" onClick={handleEdit}>
										{commons.submit}
									</Button>{" "}
									<Button color="secondary" onClick={() => toggleEdit(0)}>
										{commons.cancel}
									</Button>
								</ModalFooter>
							</Modal>
						</ShowForPermission>

						<ShowForPermission permission="company:edit-groups">
							<Modal
								className="add-model w-55"
								isOpen={modalEditGroup}
								toggle={toggleEditGroup}
							>
								<ModalHeader toggle={toggleEditGroup}>
									{commons.editGroup}
								</ModalHeader>
								<ModalBody>
									<div className="row">
										<div className="col-md-5">
											<TextField
												type="text"
												label={teamManagement.groupName}
												placeholder={teamManagement.groupName}
												name="name"
												onChange={(e) => {
													setGroup({
														...group,
														name: e.target.value,
													});
												}}
												color={errors?.name?.required ? "danger" : ""}
												errors={errors?.name}
												value={group.name}
											/>
										</div>
									</div>
									<div className="row">{AllPermissions}</div>
								</ModalBody>
								<ModalFooter>
									<Button color="primary" onClick={handleEditGroup}>
										{commons.submit}
									</Button>{" "}
									<Button color="secondary" onClick={() => toggleEditGroup(0)}>
										{commons.cancel}
									</Button>
								</ModalFooter>
							</Modal>
						</ShowForPermission>
					</div>
				</div>
			</section>
		</>
	);
}
