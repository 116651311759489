import { AuthRoute } from "components/AuthRoute";
import { PrivateRoute } from "components/PrivateRoute";
import listCompany from "modules/admin/Companies";
import CompanyInfo from "modules/admin/CompanyInfo";
import CompanyUser from "modules/admin/CompanyUsers";
import Login from "modules/auth/Login";
import BranchesRoute from "modules/branches/branchesRoute/BranchesRoute";
import BranchesTravelers from "modules/branches/ListTravelers/BranchesTravelers";
import TeamManagementBranches from "modules/branches/TeamManagement/TeamManagementBranches";
import CompaniesRoute from "modules/companies/companiesRoute/CompaniesRoute";
import DashboardCompanies from "modules/companies/dashboardCompanies/DashboardCompanies";
import labManagement from "modules/companies/labManagement/ListLab";
import AddLab from "modules/companies/labManagement/ListLab/AddLab";
import ListUser from "modules/companies/labManagement/ListUser";
import EditUser from "modules/companies/labManagement/ListUser/EditUser";
import PcrReservationsCompany from "modules/companies/PcrReservations";
import TeamManagementCompanies from "modules/companies/TeamManagement";
import Travelers from "modules/companies/travelers/Travelers";
import BranchesManagement from "modules/Labs/BranchesManagement";
import View from "modules/Labs/BranchesManagement/BranchesList/Branch/View";
import LabsRouter from "modules/Labs/LabsRouter/labsRouter";
import { default as index, default as Labs } from "modules/Labs/ListTravelers";
import PcrReservations from "modules/Labs/PcrReservations";
import PcrReservationSaudi from "modules/Labs/PcrReservationSaudi";
import PcrReservationCheckedSaudi from "modules/Labs/PcrReservationSaudi/Checked";
import TeamManagement from "modules/Labs/TeamManagement";
import React from "react";
import { Switch } from "react-router-dom";

// Lazy Components
// const App = lazy(() => import("./App"));

// Application Routes
const Routes = (
	<Switch>
		{/* Login Module */}
		<AuthRoute path="/auth/login" exact component={Login} />
		<AuthRoute path="/" exact component={Login} />
		{/* <AuthRoute path="/auth/register" exact component={Register} />
		<AuthRoute
			path="/auth/register/success"
			exact
			component={RegistrationSucceed}
		/> */}

		{/* Admin flow */}
		<PrivateRoute exact path="/admin/" component={DashboardCompanies} />
		<PrivateRoute exact path="/admin/list-company" component={listCompany} />
		<PrivateRoute exact path="/admin/company-info" component={CompanyInfo} />
		<PrivateRoute path="/admin/company-info/:id" component={CompanyInfo} />
		<PrivateRoute exact path="/admin/company-user" component={CompanyUser} />

		{/* Companies Flow */}
		<CompaniesRoute exact path="/companies/" component={DashboardCompanies} />
		<CompaniesRoute path="/companies/travelers/:status" component={Travelers} />
		<CompaniesRoute
			path="/companies/team-management"
			component={TeamManagementCompanies}
		/>
		<CompaniesRoute
			exact
			path="/companies/lab-management"
			component={labManagement}
		/>
		{/* 
		<CompaniesRoute
			exact
			path="/companies/lab-management/add"
			component={AddLab}
		/> */}
		<CompaniesRoute
			exact
			path="/companies/lab-management/ListUser/:id"
			component={ListUser}
		/>
		<CompaniesRoute
			exact
			path="/companies/lab-management/ListUser/:labId/:userId"
			component={EditUser}
		/>
		<CompaniesRoute
			exact
			path="/companies/lab-management/:id"
			component={AddLab}
		/>

		<CompaniesRoute
			exact
			path="/companies/lab-management/:id/:details"
			component={AddLab}
		/>

		<CompaniesRoute
			exact
			path="/companies/PCR-Reservations"
			component={PcrReservationsCompany}
		/>
		{/* <CompaniesRoute exact path="/companies/branches" component={Branches} />
		<CompaniesRoute exact path="/companies/users" component={Users} /> */}

		{/* Branches Route */}
		<BranchesRoute exact path="/branches/" component={BranchesTravelers} />
		<BranchesRoute
			exact
			path="/branches/team-management"
			component={TeamManagementBranches}
		/>

		{/* Labs Flow */}
		<LabsRouter exact path="/lab/" component={DashboardCompanies} />
		<LabsRouter exact path="/lab/labs-list" component={Labs} />
		<LabsRouter exact path="/lab/labs-list/:status" component={index} />
		<LabsRouter
			exact
			path="/lab/PCR-Reservations"
			component={PcrReservations}
		/>
		<LabsRouter
			exact
			path="/lab/PCR-Reservations/saudi/:lab_id/:city_id"
			component={PcrReservationSaudi}
		/>
		<LabsRouter
			exact
			path="/lab/PCR-Reservations/checked/saudi/:lab_id/:city_id"
			component={PcrReservationCheckedSaudi}
		/>
		<LabsRouter
			exact
			path="/lab/branches-management"
			component={BranchesManagement}
		/>
		<LabsRouter exact path="/lab/branches-management/:id" component={View} />
		<LabsRouter exact path="/lab/team-management" component={TeamManagement} />
		{/* Labs Reference */}
		{/* <Route exact path="/lab2/" component={DashboardCompanies} />
		<Route exact path="/lab2/labs-list2" component={Labs} />
		<Route exact path="/lab2/branches-management2" component={ListBranches} /> */}

		{/* <Route exact path="/" component={DashboardCompanies} /> */}
		{/* <Route exact path="/labs-admin" component={LabsAdmin} /> */}
		{/* <AuthRoute exact path="/login" component={Login} /> */}
		{/* <Route exact path="/list-users" component={TeamManagement} />
		<Route exact path="/lab-info" component={LabInfo} /> */}

		{/* Error404 Routes */}
		{/* <Route path="*" component={NotFound} /> */}
	</Switch>
);

export default Routes;
