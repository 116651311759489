import React, { useState } from "react";
import { useParams } from "react-router";
import Locale from "translations";
import Filter from "./Filter";
import List from "./List";

export default function Travelers() {
	const { Labs } = Locale;
	const { status } = useParams();

	const [filter, setfilter] = useState({
		lab_name: "",
		national_id: "",
		passport_number: "",
		company_name: "",
		request_date: "",
		license_number: "",
		gender: null,
	});

	return (
		<>
			<section className="main-color medical-section">
				<div className="container-fluid min-Height">
					<Filter filter={filter} setfilter={setfilter} status={status} />
					<List filter={filter} status={status} setfilter={setfilter} />
				</div>
			</section>
		</>
	);
}
